/* src/styles/header.css */
.site-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4285F4;
  padding: 10px 20px;
  color: rgb(0, 0, 0);
}

.logo h1 {
  font-size: 1.8rem;
  color: #000000;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

nav li {
  font-size: 1.2rem;
  color: rgb(0, 0, 0);
  position: relative;
}

nav li a {
  color: rgb(0, 0, 0);
  font-size: 1rem;  
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.3s ease;  /* Add smooth color transition on hover */
}

nav li a:hover {
  color: white;  /* Text color changes to white when hovered */
}

/* Dropdown styles */
.dropdown-content {
  display: none;              
  position: absolute;
  background-color: white;   
  padding: 10px;
  top: 100%;
  left: 0;
  min-width: 280px;
  font-size: 1.2rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px); /* Start with dropdown slightly lower */
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}

nav li:hover .dropdown-content {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);    
}

/* Style for each item inside the dropdown */
.dropdown-content li {
  display: block;
  margin-bottom: 0px;
  transition: background-color 0.3s ease, color 0.3s ease; 
  padding: 0px; 
}


.dropdown-content li:hover {
  background-color: #848482;   
}

.dropdown-content li a {
  color: black;         
  text-decoration: none;
  font-size: 1.2rem;
  display: block;
  width: 100%;  
}

.dropdown-content li:hover a {
  color: white;               
}
