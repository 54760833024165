/* src/styles/properties.css */
.commercial-page, .residential-page {
    padding: 40px 20px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #4285F4; /* Red heading */
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 10px;
    font-size: 1rem;
    padding: 0px 20px;
  }
  