/* src/App.css */

/* Global styles */
body, html {
  margin: 0;
  height: 100%;
  background-color: rgb(255, 255, 255); /* Black background */
  color: rgb(0, 0, 0); /* White text */
}

/* Add padding to all text elements */
p, h1, h2, h3, h4, h5, h6, a {
  padding-left: 20px; /* Left padding */
  padding-right: 20px; /* Right padding */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
