/* src/styles/contact.css */
.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  color: rgb(0, 0, 0);
  padding: 0px 20px;
  text-align: center;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  
}

.contact-form {
  
  color: black;
  padding: 40px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
}

.contact-info {
  color: white;
  padding: 40px;
  width: 30%;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.contact-info address {
  font-style: normal;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.contact-info p {
  font-size: 1.1rem;
}

.contact-form h2 {
  color: #4285F4; /* Red heading */
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form .name-fields {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.contact-form textarea {
  height: 100px;
  resize: none;
}

.contact-form button {
  background-color: #4285F4; /* Red button */
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  font-size: 1.1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #4285F4;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.contact-container h1 {
  font-size: 2.5rem;
  color: #4285F4; /* Red color for Contact Us heading */
}
