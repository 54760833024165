/* src/styles/footer.css */
.site-footer {
  display:flex;
  justify-content: space-between;
  align-items: flex-start;  /* Ensure alignment at the top */
  padding: 2px 0px;       /* Adjust padding for a cleaner look */
  background-color: #4285F4;
  color: rgb(0, 0, 0);
  
}

.footer-left {
  width: 30%;
}

.footer-middle {
  width: 30%;
  
}

.footer-right {
  width: 30%;
}

.footer-logo h1 {
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
}

.footer-middle ul {
  list-style: none;
}

.footer-middle ul li {
  margin-bottom: 1px;
}

.footer-middle ul li a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 1rem;
  
}

.footer-right address, .footer-right p {
  margin: 0px;
  line-height: 1.5;
  
}

.footer-right a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}